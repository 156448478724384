@import '@fuse/scss/core';
@import 'app/app.theme';
@import 'app/prisma/scss/_prisma';
@import 'app/spaces/style_spaces.scss';

:root {
  --main-default-font: Muli, Helvetica Neue, Arial, sans-serif;
  --main-greenColor: #1ad485;
  --main-redColor: #ff5050;
  --main-yellowColor: #fdd835;
  --main-yellowColor2: #fbc113;
  --main-grayColor: #666;

  --default-icon-color: #636363;
  --highlight-color: #f5f5f5;
  --card-color: #fff;
  --generic-row-border-color: #e0e0e0;
  --card-text-color: rgba(0, 0, 0, 0.87);

  --easy-leaflet-marker-size: 46px;
  --ux_zoom_scale: 1;
}

input[type='number'] {
  text-align: right;
}
/* css utilizado como select para operar scrollTop*/
._scrollTopPageContent {
  padding: 0;
}
.noOverflowX {
  overflow-x: hidden;
}

.clickeable_on_edit {
  pointer-events: all !important;
}

.dragDropAlert {
  border: dashed 4px var(--main-redColor) !important;
  p {
    color: var(--main-redColor) !important;
  }
}

.clickeable {
  cursor: pointer;
}

.default-click {
  cursor: default !important;
}

.no-click {
  pointer-events: none;
}
.centered {
  text-align: center;
}

.top-align {
  position: fixed !important;
  top: 0 !important;
  // max-height: 100px !important;
  max-width: 550px !important;
}

.greyColor {
  color: --main-grayColor;
}

mat-toolbar {
  mat-toolbar-row {
    h6 {
      font-size: 12px !important;
    }
  }

  .toolbar-text {
    color: #fff;
  }
}

button:not([mat-menu-item]) {
  text-transform: uppercase;
}
.fx-wrap {
  flex-wrap: wrap;
}
.sticky {
  z-index: 100;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.fx-1 {
  flex: 0 0 1%;
}

.fx-2 {
  flex: 0 0 2%;
}
.fx-2-5 {
  flex: 0 0 2.5%;
}

.fx-3 {
  flex: 0 0 3%;
}

.fx-4 {
  flex: 0 0 4%;
}

.fx-5 {
  flex: 0 0 5%;
}

.fx-6 {
  flex: 0 0 6%;
}

.fx-8 {
  flex: 0 0 8%;
}

.fx-10 {
  flex: 0 0 10%;
}

.fx-14 {
  flex: 0 0 14%;
}

.fx-15 {
  flex: 0 0 15%;
}

.fx-20 {
  flex: 0 0 20%;
}

.fx-25 {
  flex: 0 0 25%;
}

.fx-30 {
  flex: 0 0 30%;
}

.fx-35 {
  flex: 0 0 35%;
}

.fx-40 {
  flex: 0 0 40%;
}

.fx-50 {
  flex: 0 0 50%;
}
.fx-70 {
  flex: 0 0 70%;
}

.color-positive {
  color: $positive-color;
}

.color-warning {
  color: var(--main-yellowColor2) !important;
}

.color-green {
  color: var(--main-greenColor) !important;
}

.color-negative {
  color: $negative-color;
}

.last-field {
  margin-right: 32px;
}

.only-field {
  margin-right: 0px !important;
}

.button-outline {
  color: var(--main-greenColor) !important;
  border: 2px solid var(--main-greenColor) !important;
}

// .content-form {
//   max-width: 280px;
// }

.mat-form-field-disabled {
  opacity: 0.65;
}

.mat-tooltip {
  max-width: 45vw !important;
  max-width: min(45vw, 420px) !important;
  font-size: 12px;
}

.tab-icon {
  font-size: 18px;
  margin-top: 5px;
  margin-right: 5px;
}

.align-r {
  text-align: right;
}

/**
 * material-fixes.scss Incluye los fixes y customizaciones sobre componentes angular-material
 */

.manualSelectionScroll {
  height: 544px;
  overflow: auto;
}

.manualSelectionScroll table {
  min-height: 472px;
}

.manualSelectionScrollModal {
  height: 390px;
  overflow: auto;
  table {
    min-height: 390px;
  }
  .manualSelectionSpinnerHeightWithSearch {
    min-height: 390px !important;
  }
  .mat-paginator-range-label {
    margin: 0px !important;
  }
}

@media screen and (max-width: 1400px) {
  .manualSelectionScrollModal {
    height: 254px;
    overflow: auto;
    table {
      min-height: 254px;
    }
    .manualSelectionSpinnerHeightWithSearch {
      min-height: 254px !important;
    }
    .mat-paginator-range-label {
      margin: 0px !important;
    }
  }
}

mat-table {
  overflow: auto;
  overflow-x: scroll;
}

tr.collaspsed-sub-row {
  height: 0 !important;
  display: contents !important;
}

.align-r {
  text-align: right;
}
.align-l {
  text-align: left;
}
/* el diseño de columnas dinamicas implica asignar anchos fijos a las columnas
 por lo que los bordes inferiores de la fila son agregados con este css porque
  la tabla no llega a tener el largo de la fila */
.dinamicColumns {
  mat-header-row,
  mat-row {
    border-bottom: unset;
  }

  mat-cell,
  mat-header-cell {
    border-bottom: 1px solid var(--generic-row-border-color);
  }
}

@-moz-document url-prefix() {
  mat-drawer-content {
    overflow: unset !important;
  }
}

mat-drawer-content {
  overflow: unset !important;
}

mat-dialog-container {
  min-height: fit-content !important;
}

// INIT: Fix para multiple select. Fuse rompe cuando esta en checked
// mat-pseudo-checkbox {
//     width: 20px !important;
//     height: 20px !important;
// }

.mat-pseudo-checkbox-checked {
  background: $primary-color !important;
}

// END: Fix multiple select

// INIT: Modificaciones a mat-stepper
.mat-vertical-content {
  display: none !important;
}

.mat-step-icon-selected,
.mat-step-icon-state-edit,
.mat-step-icon-state-done {
  background-color: white !important;
  color: black !important;
  border: 2px solid $primary-color !important;
}

// END: Modificaciones a mat-stepper

//INIT: Modificaciones a los checkboxs de las tablas
.morePaddingForClick {
  padding-left: 0px !important;
}

.morePaddingForClick label {
  padding: 24px;
}

//END: Modificaciones a los checkboxs de las tablas

//INIT: Modificaciones a la altura de las rows de las tablas que no tienen checkboxs
.table-without-checkbox {
  min-height: 65px;
}

//END: Modificaciones a la altura de las rows de las tablas que no tienen checkboxs

//prisma-table y sus usos custom

.status-black {
  color: #000000 !important;
  font-weight: bold;
}

.status-green {
  color: var(--main-greenColor) !important;
  font-weight: bold;
}

.status-turquoise {
  color: #00b0f0 !important;
  font-weight: bold;
}

.status-red {
  color: var(--main-redColor) !important;
  font-weight: bold;
}

.color-red {
  color: var(--main-redColor) !important;
}

.status-yellow {
  color: var(--main-yellowColor2) !important;
  font-weight: bold;
}

.status-gray {
  color: var(--card-text-color) !important;
  opacity: 0.6;
  font-weight: bold;
}

.titleNoWrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 10px 0px 10px;
}

mat-row:hover .actions-btns {
  background-color: #f5f5f5;
  //rgba(245, 245, 245, 0.7);

  animation: 0.05s __fadeIn;
  animation-fill-mode: forwards;
}

mat-row:hover .actions-btnsTransparent {
  background-color: transparent !important;
  //rgba(245, 245, 245, 0.7);

  animation: 0.05s __fadeIn;
  animation-fill-mode: forwards;
}

// INIT: Spinner
mat-spinner {
  margin: auto !important;
  top: 33%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.spinner-container {
  min-height: 500px;
  // padding-top: 15%;
  pointer-events: none;
  display: flex;
}

.spinner-container-400 {
  min-height: 400px;
  // padding-top: 15%;
  pointer-events: none;
  display: flex;
}

.spinner-container-mini {
  min-height: 75px;
  height: 75px;
  pointer-events: none;
  display: flex;
  transform: scale(0.5);
}

.spinner-container-no-height {
  pointer-events: none;
  display: flex;
}

.loader {
  margin: auto !important;
  top: 33%;
  // transform: scale(1.5);
}

.w-xs-100-md-65 {
  width: 65%;
}

body:not(.prisma-not-mat-select-position-overwrites) {
  // ESTILOS PARA QUE LOS COMBOS NO TAPEN EL INPUT
  .cdk-overlay-pane {
    // transform: translateX(0px) translateY(26px) !important;
    transform: translateX(0px) !important;
  }

  .mat-select-panel {
    transform: translateY(26px) !important;
  }
}

body.prisma-mat-select-disable-animating {
  .mat-select-panel {
    &.ng-animating {
      visibility: hidden;
    }
  }
}

@media (max-width: 976px) {
  .w-xs-100-md-65 {
    width: 100%;
  }
}
/*Para que entren más solapas/tabs*/
@media (max-width: 1660px) {
  .mat-tab-link {
    padding: 0 16px !important;
    min-width: 100px !important;
  }
}
@media (max-width: 1320px) {
  .mat-tab-link {
    padding: 0 12px !important;
    min-width: 80px !important;
  }
}

.leaflet-control a {
  text-decoration: none !important;
}

// `
//        d8888 888     888 Y88b   d88P  .d8888b.
//       d88888 888     888  Y88b d88P  d88P  Y88b
//      d88P888 888     888   Y88o88P   Y88b.
//     d88P 888 888     888    Y888P     "Y888b.
//    d88P  888 888     888    d888b        "Y88b.
//   d88P   888 888     888   d88888b         "888
//  d8888888888 Y88b. .d88P  d88P Y88b  Y88b  d88P
// d88P     888  "Y88888P"  d88P   Y88b  "Y8888P"
// `

.h-260_mh {
  max-height: 260px !important;
}

.h-240_mh {
  max-height: 240px !important;
}

//SNACK BAR RED
.red-snackbar {
  background: var(--main-redColor) !important;
  .mat-button-wrapper {
    color: #fff;
  }
}

//Iconos colores
.mat-icon.color-verde {
  color: var(--main-greenColor) !important;
}
.mat-icon.color-rojo {
  color: var(--main-redColor) !important;
}
.mat-icon.semi-disabled {
  opacity: 0.35;
}

.status-red {
  color: var(--main-redColor) !important;
}

.status-warning {
  color: var(--main-yellowColor2) !important;
}

//Saltos de lí­nea para los tooltips
.mat-tooltip {
  white-space: pre-line !important;
}

//Caja para imprimir data
._infoBox {
  padding: 16px;
  margin: 16px 0;
  display: block;
  background-color: #fafafa;
  font-size: 12px;
  white-space: pre;
}

//Evita que los mat-selects crezcan mÃ¡s de lo que pueden de ancho
._select-no-overflow .mat-select-value-text > span {
  white-space: initial;
  max-height: 20px;
  display: block;
}

//Atributo hidden
[hidden] {
  display: none !important;
}

._hidden,
.__hidden {
  visibility: hidden;
  pointer-events: none;
}

._superHidden {
  visibility: hidden;
  pointer-events: none;
  height: 0;
  overflow: hidden;
  z-index: -10;
}

._hiddenHard,
.__hiddenHard {
  visibility: hidden;
  pointer-events: none;
  display: none !important;
}

._SoftHidden,
.__Softhidden {
  opacity: 0;
  pointer-events: none;
}

//Clase genÃ©rica para lookUps dentro de selects
.lookUpInputGeneric {
  padding: 16px 16px;
  padding-bottom: 12px;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  border-bottom: 1px solid var(--generic-row-border-color);
}

.generic-lookup-panel-limited {
  min-width: 250px !important;
}

.lookUpInput input {
  padding-left: 0;
}

content ian-header-toolbar {
  overflow-x: hidden !important;
}

.ian-header-subtitle {
  font-size: small;
  opacity: 0.5;
}

//No Clicks in steppers
mat-vertical-stepper.no-click,
.no-click-stepper mat-vertical-stepper {
  pointer-events: none;
}

//Estilos Custom para steppers
._stepper_special {
  background-color: #fff;
  .mat-step-icon-state-edit {
    filter: grayscale(1) !important;
    opacity: 0.6;
  }
  .mat-step-label-active:not(.mat-step-label-selected) {
    opacity: 0.6;
  }
  .mat-step-optional {
    display: none !important;
  }
}
.prisma_stepper_wrapp mat-vertical-stepper {
  @extend ._stepper_special;
}

//Disabled pero como clase
._disabled {
  opacity: 0.7;
  pointer-events: none !important;
  user-select: none;
  .mat-select-arrow-wrapper {
    visibility: hidden;
  }
}

._disabledAlt {
  opacity: 0.75;
  pointer-events: none !important;
  user-select: none;
  .mat-select-arrow-wrapper {
    visibility: hidden;
  }
}

._disabledGray {
  @extend ._disabled;
  pointer-events: all !important;
  filter: grayscale(1);
}

.ng-invalid ._autoDisableFormButtons button,
.ng-invalid button._autoDisableFormButtons {
  @extend ._disabled;
  pointer-events: all !important;
  filter: grayscale(1);
}

//Disabled pero como clase
._noSmallLabel-andLabelIsValue {
  .mat-form-field-should-float .mat-form-field-label {
    display: none !important;
  }
  .mat-form-field-required-marker {
    display: none !important;
  }
  .mat-form-field-appearance-legacy:not(.mat-form-field-disabled) .mat-form-field-label {
    color: rgba(0, 0, 0, 1) !important;
  }
  .mat-form-field-appearance-legacy .mat-form-field-disabled .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-label,
  .mat-form-field-disabled .mat-form-field-label {
    opacity: 0.65 !important;
  }
}

.mat-badge-medium .mat-badge-content {
  color: #fff !important;
  font-weight: bold !important;
}

@keyframes anim_pop {
  0% {
    transform: scale(1);
  }

  15% {
    transform: scale(0);
  }

  65% {
    transform: scale(0);
  }

  80% {
    transform: scale(1.8);
  }

  90% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes anim_popB {
  0% {
    transform: scale(1);
  }

  15% {
    transform: scale(0);
  }

  65% {
    transform: scale(0);
  }

  80% {
    transform: scale(1.8);
  }

  90% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

// Global isInSave
@keyframes lds-dual-ring-float {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

span.prisma-content-globalsave {
  display: contents !important;
}

%prisma-button-in-save {
  filter: grayscale(1);
  background-color: #999 !important;
  pointer-events: none !important;
  .mat-button-wrapper {
    color: transparent !important;
  }
  .mat-button-wrapper:before {
    content: ' ';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    margin: 0px;
    margin-top: -8px;
    margin-left: -9px;
    border-radius: 50%;
    border: 2.5px solid #fff;
    border-color: #fff #fff transparent;
    animation: lds-dual-ring-float 0.6s ease-in-out infinite;
  }
}

body._global_AppInLoad {
  .prisma-content-globalsave {
    pointer-events: none !important;
    > div {
      opacity: 0.6 !important;
    }
  }
  .prisma-button-globalsave {
    @extend %prisma-button-in-save;
  }
  .prisma-button-globalsave-secundary {
    filter: grayscale(1);
    background-color: #999 !important;
    pointer-events: none !important;
    .mat-button-wrapper {
      color: #fff !important;
    }
  }
}

.prisma-button-in-save {
  @extend %prisma-button-in-save;
}

.spinnerWhenDisabledButton:disabled {
  pointer-events: none !important;
  .mat-button-wrapper,
  .mat-button-base {
    color: transparent;
  }
  .mat-button-wrapper:before,
  .mat-button-base:before {
    content: ' ';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    margin: 0px;
    margin-top: -8px;
    margin-left: -9px;
    border-radius: 50%;
    border: 2.5px solid #fff;
    border-color: #fff #fff transparent;
    animation: lds-dual-ring-float 0.6s ease-in-out infinite;
  }
  .hiddenText {
    display: none;
  }
}

.spinnerWhenDisabledButton_darkMode:disabled {
  pointer-events: none !important;
  .mat-button-wrapper,
  .mat-button-base {
    color: transparent;
  }
  .mat-button-wrapper:before,
  .mat-button-base:before {
    content: ' ';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    margin: 0px;
    margin-top: -16px;
    margin-left: -9px;
    border-radius: 50%;
    border: 2.5px solid #000;
    border-color: #000 #000 transparent;
    animation: lds-dual-ring-float 0.6s ease-in-out infinite;
  }
  .hiddenText {
    display: none;
  }
}

.add-attrib-gen-wrapp-noborder {
  .add-attrib-gen {
    border: none;
    padding-left: 0;
    margin-left: -4px;
    mat-icon {
      display: inline-block !important;
      font-size: 20px;
    }
  }
}

@keyframes waitAnim {
  0% {
    transform: scale(0);
  }
  99% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.cdk-hidden {
  opacity: 0 !important;
}
body.theme-default .mat-snack-bar-container.prisma-new-notification {
  background-color: #fafafa;
  border-radius: 6px;
  padding: 24px 18px;

  position: absolute;
  top: 72px;
  right: 0px;

  .mat-simple-snackbar {
    > span:first-child {
      color: #636363;
      font-size: 16px;
      white-space: pre-wrap;
      &:before {
        content: '🔔';
        font-size: 28px;
        vertical-align: middle;
        padding: 12px;
        padding-bottom: 10px;
        padding-left: 4px;
        padding-right: 16px;
        opacity: 0.5;
        float: left;
        filter: grayscale(1);
      }
    }
  }
}

.title-filter {
  font-weight: 600;
  margin-bottom: 0px;
  font-size: 16px;
}

.forceBlock {
  display: block !important;
}

._cardMinPadding {
  .mat-card {
    padding: 4px !important;
  }
}

._disableOnEditWrapp > * {
  pointer-events: none !important;
}

.prisma_disabled {
  pointer-events: none !important;
  filter: grayscale(50%);
  opacity: 0.5;
}

._disableEvents > * {
  pointer-events: none !important;
}
._disableEvents > * > .box-strategy-item,
._disableEvents > :not(._activeEvents) {
  filter: grayscale(50%);
}
._disableEvents mat-form-field:not(._activeEvents) {
  opacity: 0.6;
}
._disableEvents ._activeEvents {
  filter: grayscale(0);
}

._activeEvents {
  pointer-events: visible !important;
}

._disableSelect {
  user-select: none !important;
}

/*TEST E2E / Dejarlos al final*/
body.TEST_E2E {
  form,
  .mat-drawer-inner-container {
    display: block !important;
  }
}

/*Nuevas Toasts*/
.toast-top-right.toast-container {
  margin-top: calc(70px + 2px) !important;
  margin-right: 4px !important;
  transform: scale(0.9);
  transform-origin: 100% 0%;
}
.ngx-toastr-notif {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.45), 0px 2px 4px rgba(0, 0, 0, 0.2) !important;
  margin-bottom: 6px !important;
  background-position-y: 16px !important;
  background-image: url('data:image/svg+xml;utf8,<svg height="300" width="300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><switch><g><path fill="rgb(255,255,255)" d="M89.5 63.8l-5.9-7.3c-1.8-2.2-2.8-5-2.8-7.9v-7.9c0-12.4-7.3-23.4-18.5-28.2v-.4c0-5.3-4.3-9.6-9.6-9.6h-5.3c-5.3 0-9.6 4.3-9.6 9.6v.4c-11.2 4.9-18.5 15.9-18.5 28.2v7.9c0 2.9-1 5.6-2.8 7.9l-5.9 7.3C7.9 67 7.3 71.3 9.1 75c1.8 3.7 5.4 6 9.5 6h13c1 9.2 8.9 16.5 18.4 16.5S67.4 90.3 68.4 81h13c4.1 0 7.8-2.3 9.5-6 1.8-3.7 1.2-8-1.4-11.2zM50 89.8c-5.3 0-9.6-3.8-10.6-8.8h21.2c-1 5-5.3 8.8-10.6 8.8zm33.9-18.1c-.2.5-1 1.6-2.6 1.6H18.6c-1.6 0-2.3-1.1-2.6-1.6-.2-.5-.7-1.8.4-3l5.9-7.3c2.9-3.6 4.5-8.1 4.5-12.7v-7.9c0-10 6.4-18.8 15.9-21.9 1.6-.5 2.6-2 2.6-3.7v-3c0-1 .9-1.9 1.9-1.9h5.3c1 0 1.9.9 1.9 1.9v3c0 1.7 1.1 3.1 2.6 3.7 9.5 3.1 15.9 11.9 15.9 21.9v7.9c0 4.6 1.6 9.1 4.5 12.7l5.9 7.3c1.3 1.2.9 2.5.6 3zM73.2 11.2c4.7 3.6 8.4 8.3 10.9 13.6.6 1.4 2 2.2 3.5 2.2.5 0 1.1-.1 1.6-.4 1.9-.9 2.8-3.2 1.9-5.1C88.1 15.1 83.6 9.3 78 5c-1.7-1.3-4.1-1-5.4.7-1.4 1.8-1.1 4.2.6 5.5zM10.9 26.7c.5.2 1.1.4 1.6.4 1.5 0 2.8-.8 3.5-2.2 2.5-5.3 6.2-10.1 10.9-13.6 1.7-1.3 2-3.7.7-5.4-1.3-1.7-3.7-2-5.4-.7C16.5 9.5 12 15.2 9 21.6c-.9 2-.1 4.2 1.9 5.1z"/></g></switch></svg>') !important;
}
.ngx-toastr-notifOK {
  background-color: var(--main-greenColor) !important;
  .toast-message,
  .toast-title {
    white-space: pre-line !important;
  }
  .toast-title {
    margin-bottom: 8px;
  }
  .linkInNotification {
    color: #fff !important;
    text-decoration: underline !important;
  }
}
.ngx-toastr-notifFAIL {
  background-color: var(--main-redColor) !important;
  .toast-message,
  .toast-title {
    white-space: pre-line !important;
  }
  .toast-title {
    margin-bottom: 8px;
  }
}

tooltip.tooltip {
  background-color: rgb(100, 100, 100);
}

.menuAsCombo {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 12px;
    right: 4px;
    display: block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000;
    opacity: 0.54;
    clear: both;
  }
}

.menuAsComboSeparator {
  height: 8px;
}
button.menuAsCombo {
  text-transform: none !important;
  text-align-last: left !important;
  padding: 0px 0px !important;
  margin-top: 16px;
  margin-bottom: 16px;
  padding-top: 2px;
  padding-bottom: 2px;
  background: #fff !important;
  border-bottom: 1px solid #949494 !important;
  line-height: 28px;
  min-height: 28px;
  position: relative;

  .opt_label {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.42);
    pointer-events: none;
    &.withValues {
      position: absolute;
      top: -11px;
      font-size: 11px;
    }
  }
  .opt_value {
    font-size: 16px;
    pointer-events: none;
  }

  &:hover {
    background: #fff !important;
  }
  .mat-ripple,
  .mat-button-focus-overlay {
    background: none !important;
  }
  .mat-button-wrapper {
    padding: 0;
    margin: 0;
    line-height-step: 16;
  }
}
.menuAsComboOptionsWrapp {
  padding: 16px;
  min-width: 220px;
  mat-checkbox {
    display: block;
  }
  .menuAsComboOptionsSeparator {
    height: 20px;
  }
}

#image-viewer-default,
.image-viewer-default {
  width: 100%;
  height: 100%;
  margin-top: 0;
  .prev,
  .next {
    display: none;
  }
  .inline-icon {
    display: none;
  }
  .footer-info {
    display: none;
  }
  .image-container {
    bottom: 0 !important;
    background-color: transparent !important;
    border: none !important;
  }
  .iv-snap-image {
    max-width: initial;
  }
  .iv-loader {
    border-bottom: 1.1em solid #999999 !important;
    border-left: 1.1em solid transparent !important;
  }
  .iv-image-view {
    background-color: #fafafa;
  }
  .iv-zoom-slider {
    display: none;
  }
}
._full-width-dialog .mat-dialog-container {
  max-width: 100vw !important;
  width: 100vw !important;
}

//Fix para que no se desfacen los buscadores avanzados laterales
mat-sidenav[mode='side'] [fxlayout='column'] form {
  max-width: 299px;
}

.publishStatusIsProcessingWarning {
  text-align: center;
  padding: 20px;
  padding-top: 80px !important;
}

.publish_alert1,
.publish_alert2__ {
  margin-bottom: 24px;
}
.publish_alert1 img {
  float: right;
  width: 64px;
  margin-left: 16px;
  margin-bottom: 8px;
  margin-top: -12px;
}

.mat-sidenav-max {
  max-width: 300px;
}
mat-sidenav {
  advanced-search-header > div {
    max-width: 299px !important;
  }
  advanced-search-header mat-toolbar {
    max-width: 299px !important;
  }
}

ngx-material-timepicker-toggle {
  transform: scale(0.75);
  opacity: 0.8;
  svg {
    fill: var(--main-greenColor);
    fill: #000;
  }
}

.ngx-timepicker-control__input {
  font-size: 14px !important;
  border: none !important;
}

ngx-timepicker-control:after {
  filter: grayscale(1);
  display: none;
}

.ngx-timepicker-field-wrapp__ mat-label {
  opacity: 0.6;
}

.ngx-timepicker {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5) !important;
  margin-bottom: 20px !important;
  margin-top: 8px !important;
}

.timepickerDisabledControls {
  .ngx-timepicker-control__arrows {
    display: none !important;
  }
  input {
    pointer-events: none !important;
  }
}

.datepickerDisabledControls {
  .mat-form-field-wrapper {
    pointer-events: none !important;
  }
  .mat-form-field-suffix {
    pointer-events: all !important;
  }
}

.mde-popover-content {
  z-index: 20000;
}

.cdk-overlay-container .mat-tooltip-panel {
  pointer-events: none !important; //DES-1668
}

.mat-button-toggle-checked {
  color: #fff !important;
  background-color: var(--main-greenColor) !important;
}

.mat-slider.noAnimation {
  .mat-slider-thumb-container {
    transition: none;
  }
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.filterBW {
  // filter: contrast(0.75) grayscale(1) sepia(50%) hue-rotate(160deg) grayscale(0.85);
  filter: contrast(0.85) grayscale(1) sepia(30%) hue-rotate(170deg) grayscale(0.65);
}

.VA_middle {
  vertical-align: -webkit-baseline-middle;
}

@if true {
  //UXV2 / DES-1420 | DES-1416

  html._UXV2_ {
    & app {
      --ux_zoom_scale: 0.875; //Si el valor es distinto de uno hay que aplicar algunos fixes, ej: _fixGetBoundingClientRectScale

      @if true {
        zoom: var(--ux_zoom_scale);

        #container-3 > .ps__rail-x {
          display: none !important;
        }

        navbar-vertical-style-1 .ps__rail-x {
          display: none !important;
        }

        .leaflet-map-pane {
          zoom: calc(1 / var(--ux_zoom_scale));
        }

        .leaflet-popup-content {
          font-size: 13px !important;
        }

        .leaflet-popup-close-button {
          display: none;
        }

        .mat-slider-fix-scale-wrapper {
          zoom: calc(0.95 / var(--ux_zoom_scale));
        }
      }
    }

    //Titulares más chicos
    ian-header-toolbar {
      margin-top: -64px !important;
      .heading.toolbar-text:not(h1) {
        display: none;
      }
    }

    mat-card-content ian-header-toolbar {
      margin-top: 0px !important;
    }

    ._UXV2_90Flex_content {
      min-width: 100% !important;
    }

    //Tablas Fixed sin doble card
    @media (min-width: 960px) and (min-height: 620px) {
      --mat-table-offset: 0px;

      .cdk-virtual-scroll-viewport-uxv2-min-height {
        min-height: calc((100vh / var(--ux_zoom_scale)) - (332px + var(--mat-table-offset))) !important;
      }

      mat-sidenav-content {
        &.UXv2_noContentElevationTable ian-card:not(.UXv2_noAutoHeight) .content-elevation {
          //Le saca card a la tabla
          margin: -16px !important;
          margin-top: 1px !important;
          margin-bottom: -16px !important;
          box-shadow: none !important;
        }
      }

      mat-sidenav-content {
        &.UXv2_withFixedScrollTable ian-header-masive-action-and-filter-v2 {
          z-index: 1;
        }

        &.UXv2_withFixedScrollTable ian-card:not(.UXv2_noAutoHeight) > mat-card {
          & > mat-card-content {
            & > .content-elevation {
              //Le saca card a la tabla
              margin: -16px !important;
              margin-top: 1px !important;
              margin-bottom: -16px !important;
              box-shadow: none !important;
            }

            .mat-table,
            mat-table,
            .overflow-table {
              //alto de la tabla fixed
              overflow: scroll;
              height: calc((100vh / var(--ux_zoom_scale)) - (332px + var(--mat-table-offset)));
              max-height: calc((100vh / var(--ux_zoom_scale)) - (332px + var(--mat-table-offset)));

              .mat-table,
              mat-table,
              .overflow-table {
                overflow: visible;
                height: auto;
                max-height: auto;
              }
            }

            .mat-paginator-container {
              //achica alto del paginado
              height: 60px !important;
            }
          }
        }

        //Mapas con tablas
        .mapBig .easy-leaflet-map {
          height: calc((100vh / var(--ux_zoom_scale)) - (320px - 60px)) !important;
        }

        &.UXv2_noScroll ian-card > mat-card {
          max-height: calc(100% - 48px);
          overflow: hidden;
        }

        //FIX botones de listados
        &.UXv2_withFixedScrollTable .UXv2_actions-btns {
          text-align: right;
          right: 8px !important;
          padding-right: 8px !important;
          margin-top: calc(-22px + var(--scroll-top-offset-prisma-table));
        }

        //FIX Paginator
        &.UXv2_withFixedScrollTable {
          mat-paginator {
            z-index: 1;
            position: relative;
            overflow: hidden;
            @media (max-width: 1060px) {
              .mat-paginator-page-size-label {
                display: none !important;
              }
            }
          }

          &.UXv2_smallPaginator {
            @media (max-width: 1360px) {
              .mat-paginator-page-size-label {
                display: none !important;
              }
              .mat-paginator-range-label {
                margin: 0 16px 0 24px !important;
              }
            }
          }
        }
      }
    }
  }

  @media print {
    html._UXV2_ app {
      --ux_zoom_scale: 1 !important;
    }
  }
}

@keyframes __fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

body.theme-default .mat-elevation-z1 {
  box-shadow: 0px 1px 1px rgb(0 0 0 / 10%), 0px 1px 2px rgb(0 0 0 / 8%), 0px 1px 2px rgb(0 0 0 / 6%);
}

fuse-sidebar.global-settings-panel {
  width: 420px;
  max-width: 420px;
}

ian-interfaces-log-main-detail-mesagge-item:last-child .messageItem {
  border-bottom: none !important;
  padding-bottom: 0;
  margin-bottom: 0;
}

.mat-tab-labels {
  padding: 8px 0;
}

.mat-tab-label .mat-icon {
  vertical-align: middle;
}

.mat-expansion-panel-header {
  height: 64px;
}
