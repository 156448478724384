@include mat-core;

// -----------------------------------------------------------------------------------------------------
// @ Custom color maps for Fuse
// -----------------------------------------------------------------------------------------------------
$fuse-white: (
  500: white,
  contrast: (
    500: $dark-primary-text,
  ),
);

$fuse-black: (
  500: black,
  contrast: (
    500: $light-primary-text,
  ),
);

$fuse-navy: (
  50: #ececee,
  100: #c5c6cb,
  200: #9ea1a9,
  300: #7d818c,
  400: #5c616f,
  500: #3c4252,
  600: #353a48,
  700: #2d323e,
  800: #262933,
  900: #1e2129,
  A100: #c5c6cb,
  A200: #9ea1a9,
  A400: #5c616f,
  A700: #2d323e,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$md-prisma-background: (
  50: #e6e8ea,
  100: #c1c6cb,
  200: #97a0a8,
  300: #6d7985,
  400: #4e5d6a,
  500: #2f4050,
  600: #2a3a49,
  700: #233240,
  800: #1d2a37,
  900: #121c27,
  A100: #68afff,
  A200: #3594ff,
  A400: #0279ff,
  A700: #006de8,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-prisma-primary: (
  50: #e4faf1,
  100: #bcf3dd,
  200: #8fecc6,
  300: #62e4af,
  400: #40de9e,
  500: #1ed88d,
  600: #1ad485,
  700: #16ce7a,
  800: #12c870,
  900: #0abf5d,
  A100: #eafff3,
  A200: #b7ffd5,
  A400: #84ffb7,
  A700: #6affa8,
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-prisma-secondary: (
  50: #ffeaea,
  100: #ffcbcb,
  200: #ffa8a8,
  300: #ff8585,
  400: #ff6a6a,
  500: #ff5050,
  600: #ff4949,
  700: #ff4040,
  800: #ff3737,
  900: #ff2727,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffd4d4,
  A700: #ffbbbb,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Generate Fuse color classes for custom palettes
$custom_palettes: (
  fuse-white: $fuse-white,
  fuse-black: $fuse-black,
  fuse-navy: $fuse-navy,
  md-prisma-background: $md-prisma-background,
  md-prisma-primary: $md-prisma-primary,
  md-prisma-secondary: $md-prisma-secondary,
);

@include fuse-color-classes($custom_palettes);

// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------

// Angular Material typography
$typography: mat-typography-config(
  $font-family: 'Muli, Helvetica Neue, Arial, sans-serif',
  $title: mat-typography-level(20px, 32px, 600),
  $body-2: mat-typography-level(14px, 24px, 600),
  $button: mat-typography-level(14px, 14px, 600),
  $input: mat-typography-level(16px, 1.125, 400),
);

// Setup the typography
@include angular-material-typography($typography);

// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------

// Import app and page component themes here to enable theming for them
@import 'src/app/layout/components/chat-panel/chat-panel.theme';
@import 'src/app/layout/components/toolbar/toolbar.theme';

// Define a mixin for easier access
@mixin components-theme($theme) {
  // Layout components
  @include chat-panel-theme($theme);
  @include toolbar-theme($theme);
}

// -----------------------------------------------------------------------------------------------------
// @ Define the default theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
// $default-primary-palette: mat-palette($fuse-navy);
$default-primary-palette: mat-palette($md-prisma-background);
// $default-accent-palette: mat-palette($mat-light-blue, 600, 400, 700);
$default-accent-palette: mat-palette($md-prisma-primary, 600, 400, 700);
// $default-warn-palette: mat-palette($mat-red);
$default-warn-palette: mat-palette($md-prisma-secondary);

// Create the Material theme object
$theme: mat-light-theme($default-primary-palette, $default-accent-palette, $default-warn-palette);

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-default {
  // Create an Angular Material theme from the $theme map

  @include angular-material-theme($theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($theme);

  // Apply the theme to the user components
  @include components-theme($theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $default-primary-palette,
    accent: $default-accent-palette,
    warn: $default-warn-palette,
  );

  @include fuse-color-classes($palettes);
}
// -----------------------------------------------------------------------------------------------------
// @ Define a dark-theme dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$dark-theme-primary-palette: mat-palette($fuse-navy);
$dark-theme-accent-palette: mat-palette($md-prisma-primary, 600, 400, 700);
$dark-theme-warn-palette: mat-palette($md-prisma-secondary);

$custom-background-color: #628cc9;

// Create the Material theme object
$dark-theme: mat-dark-theme($dark-theme-primary-palette, $dark-theme-accent-palette, $dark-theme-warn-palette);

$dark-theme-background-color: #262b35;
$dark-theme-card-color: #1f2229;
$dark-theme-card-color-b: #1f252f;
$dark-theme-card-color-c: #2d323e;

$dark-theme-purple: #450f83;
$dark-theme-purple-b: #6316bb;
$dark-theme-purple-c: #9c45ff;

$dark-theme-green: #267956;
$dark-theme-green-b: #19bb76;
$dark-theme-green-c: #1ad485;

// Add ".theme-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-dark {
  // Generate the Angular Material theme

  // Insert custom background color
  $extended-theme: map-get($dark-theme, background);
  $extended-theme: map_merge(
    $extended-theme,
    (
      background: $dark-theme-background-color,
      card: $dark-theme-card-color-b,
      dialog: $dark-theme-card-color-b,
    )
  );
  $dark-theme: map_merge(
    $dark-theme,
    (
      background: $extended-theme,
    )
  );

  @include angular-material-theme($dark-theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($dark-theme);

  // Apply the theme to the user components
  @include components-theme($dark-theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $dark-theme-primary-palette,
    accent: $dark-theme-accent-palette,
    warn: $dark-theme-warn-palette,
  );

  @include fuse-color-classes($palettes);
}

body.theme-dark {
  --main-darkCard: #1f252f;
  --card-color: #1f252f;
  --card-text-color: #ffffff;
  --default-icon-color: #aaafbd;
  --highlight-color: #31353d;
  --generic-row-border-color: #697484;
  --main-redColor: #ef6b5a;
  --main-greenColor____: #9c45ff;

  #prisma-header-bar.mat-toolbar {
    background-color: $dark-theme-background-color !important;
    background-color: $dark-theme-purple !important;

    .toolbar-separator {
      background: rgb(255 255 255 / 20%);
    }
  }

  .grey-toolbar,
  #spcg_titleBar {
    background-color: $dark-theme-purple-b !important;

    h1 {
      color: #fff;
      opacity: 1 !important;
      margin-top: 4px;
    }

    .mat-raised-button {
      background-color: $dark-theme-purple;
      border: solid 1px rgb(0 0 0 / 70%);
    }

    .mat-raised-button.mat-accent.mat-button-disabled,
    .mat-raised-button.mat-button-disabled.mat-button-disabled {
      color: rgba(255, 255, 255, 0.5);
      background-color: $dark-theme-purple-b;
    }

    .mat-raised-button.mat-accent,
    .mat-fab.mat-accent {
      background-color: $dark-theme-purple-c;
    }
  }

  .mat-sidenav {
    background-color: $dark-theme-card-color-b;

    .mat-toolbar {
      background-color: transparent;
      color: var(--default-icon-color);
    }
  }

  .mat-drawer-side {
    border-right: solid 1px rgb(0 0 0 / 30%);
  }

  .mat-row-highlight-no-pointer:hover,
  .mat-row-highlight-no-pointer:hover .actions-btns {
    background-color: #31353d !important;

    .icon-actions-btns {
      color: #fff !important;
    }

    .mat-button-disabled .mat-icon-no-color {
      opacity: 0.5;
    }
  }

  .mat-raised-button {
    background-color: $dark-theme-background-color;
  }

  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $dark-theme-purple-c;
  }

  .mat-tab-nav-bar.mat-primary .mat-ink-bar,
  .mat-tab-group.mat-primary .mat-ink-bar {
    background-color: $dark-theme-green-c;
  }

  .mat-card:not([class*='mat-elevation-z']) {
    // border: solid 1px #5d6b8480;
    // border: solid 1px #0008;
    border: solid 1px rgb(93 107 132 / 50%);
  }

  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #ffffff70;
  }

  mat-card-content .mat-paginator-container {
    background: #0002;
  }

  .mat-select-panel {
    border: solid 1px #0008;
  }

  .mat-select-panel:not([class*='mat-elevation-z']),
  .mat-card:not([class*='mat-elevation-z']) {
    box-shadow: 1px 1px 3px 3px #0004 0px 0px 12px 12px #0004;
  }

  .loaderTabs {
    background: $dark-theme-card-color-b;
  }

  button.menuAsCombo {
    background: $dark-theme-card-color-b !important;
    color: var(--card-text-color);
    .opt_label {
      color: var(--card-text-color);
      opacity: 0.7;
      font-weight: normal;
    }
    .opt_value {
      font-weight: normal;
    }
    &:after {
      opacity: 0.7;
      border-top: 5px solid var(--card-text-color);
    }
  }

  .mat-stroked-button:not(.mat-button-disabled) {
    border-color: #1ad485 !important;
  }

  ian-no-items-found img {
    opacity: 0.2;
    mix-blend-mode: hard-light;
  }

  .IMV2warnings,
  .actionsV2warnings {
    color: var(--main-yellowColor2);
    margin-bottom: -12px;
  }

  .conteiner-text-rule {
    background: rgb(0 0 0 / 25%) !important;
    border: solid 1px rgb(93 107 132 / 75%);
  }

  .box-strategy-item {
    background-color: rgb(0 0 0 / 25%);
    border: solid 1px rgb(93 107 132 / 75%);
  }

  .content-card {
    background-color: rgb(0 0 0 / 25%);
    border: solid 1px rgb(93 107 132 / 75%);
  }

  .lamp_svg {
    filter: invert(1);
  }

  ._gris {
    &.mat-header-cell {
      background-color: #2f3846 !important;
      border-right: 1px solid rgb(93 107 132 / 75%) !important;
    }
  }

  .paretoHeader {
    border-right: none !important;
  }

  .link-class2 {
    color: #fff;
  }

  .mat-form-field.mat-focused .mat-form-field-label {
    color: rgba(255, 255, 255, 0.7);
  }

  .lookUpInput {
    background-color: var(--card-color);
  }

  .lookUpInput input {
    background-color: var(--card-color);
    color: #fff;
  }

  fuse-nav-vertical-item .active {
    background-color: $dark-theme-purple-c !important;
    color: #ffffff !important;
  }

  .status-turquoise {
    color: #a1d5e9 !important;
  }

  .ian-header-subtitle {
    opacity: 0.85;
  }

  .background-fullsecreen {
    background-color: #262b35 !important;
  }

  .mat-chip.mat-standard-chip {
    background-color: #383f4b;
  }

  .status-black {
    color: #fff !important;
  }

  .easy-leaflet-map-wrapp {
    filter: saturate(0.5) hue-rotate(345deg) contrast(0.9);
  }

  .mat-expansion-panel-header {
    background-color: rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid #69748480;
  }

  .mat-tab-labels,
  .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
    background-color: rgba(0, 0, 0, 0.25);
  }

  .mat-tab-label-active {
    opacity: 1;
  }
  .mat-tab-label-active .mat-tab-label-content {
    color: #fff !important;
    opacity: 1;
  }

  .mat-step-header .mat-step-icon {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .help-container-title {
    background-color: #0003 !important;
    .help-title,
    .help-icon-close {
      color: #fff !important;
    }
  }

  .tag-button {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    line-height: 20px;
    padding: 4px 10px;
    font-size: 14px;
    margin: 5px;
  }

  .tableWrappSpecial_A .noElements,
  .tableWrappSpecial_B .noElements,
  .tableWrappSpecial_C .noElements {
    mix-blend-mode: soft-light;
  }

  .blendImgDarkMode {
    mix-blend-mode: soft-light;
  }
  .example-box.cdk-drag-disabled {
    background: rgba(255, 255, 255, 0.2);
    cursor: no-drop;
  }

  .example-box {
    padding: 10px 10px;
    border-bottom: solid 1px rgba(255, 255, 255, 0.2);
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: move;
    background: rgba(0, 0, 0, 0.25);
    font-size: 14px;
  }

  mat-row.highlight,
  mat-row:hover {
    .dropDown-icon {
      visibility: visible;
    }

    .btn-actions {
      background-color: rgba(255, 255, 255, 0.2) !important;
      border-radius: 20px;
    }
  }

  .spinnerWhenDisabledButton_darkMode:disabled {
    pointer-events: none !important;
    .mat-button-wrapper,
    .mat-button-base {
      color: transparent;
    }
    .mat-button-wrapper:before,
    .mat-button-base:before {
      content: ' ';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 18px;
      height: 18px;
      margin: 0px;
      margin-top: -16px;
      margin-left: -9px;
      border-radius: 50%;
      border: 2.5px solid var(--card-text-color);
      border-color: var(--card-text-color) var(--card-text-color) transparent;
      animation: lds-dual-ring-float 0.6s ease-in-out infinite;
    }
    .hiddenText {
      display: none;
    }
  }
}
